<template>
  <base-modal-ex :active.sync="isActive"
    :animation-in="animationIn"
    :animation-out="animationOut"
    :animation-duration="animationDuration"
    :can-cancel="false"
    has-modal-card>
    <div class="model-content">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">
            <slot name="text-title" />
          </p>
        </header>
        <section class="modal-card-body">
          <article class="message is-info">
            <div class="message-body">
              <div class="field-body">
                <div class="field">
                  <label class="label">Quote Labour Times</label>
                  <span class="has-text-weight-bold has-text-primary">{{ quoteTimesType }}</span>
                </div>
                <div class="field">
                  <label class="label">Vehicle Body Style</label>
                  <span class="has-text-weight-bold has-text-primary">{{ bodyShape }}</span>
                </div>
              </div>
            </div>
          </article>
          <div v-if="finishFetchingTemplates && !isTemplateListNonEmpty"
            class="field">
            <span class="has-text-danger is-italic">
              <i class="mdi mdi-alert-outline" />
              No templates available. Please contact your admin to create a new template under
              <span class="has-text-weight-bold">Quote > Quote Template.</span>
            </span>
          </div>
          <div class="field">
            <label class="label">{{ label }}</label>
            <div class="select is-fullwidth">
              <select v-model="selectedTemplate"
                :disabled="finishFetchingTemplates && !isTemplateListNonEmpty">
                <option value="null"
                  disabled>Select</option>
                <option v-for="(template, index) in templates"
                  :key="index"
                  :value="template">{{ template.name }}</option>
              </select>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot"
          :style="`justify-content: flex-${buttonLocation}`">
          <button class="button is-primary"
            @click="add()"
            :disabled="!isTemplateSelected">Apply</button>
          <button class="button"
            @click="close()">Close</button>
        </footer>
      </div>
    </div>
  </base-modal-ex>
</template>
<script>

import BaseModalEx from '@/components/BulmaModal/BaseModalEx'
import QuoteTemplateService from '@/services/QuoteTemplateService'
import { QuoteTemplateListColumns } from '@/views/quotetemplate/columns.js'
import { mapGetters } from 'vuex'

export default {
  name: 'QuoteTemplateApplyModal',
  components: {
    BaseModalEx
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    animationIn: {
      type: String,
      default: 'fadeIn'
    },
    animationOut: {
      type: String,
      default: 'fadeOut'
    },
    animationDuration: {
      type: [String, Number],
      default: 0.3
    },
    buttonLocation: {
      type: String,
      default: 'end'
    },
    quoteTimesType: {
      type: String,
      default: ''
    },
    vehicleBodyId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      filter: {
        name: '',
        sortColumn: QuoteTemplateListColumns[0].name,
        sortOrder: QuoteTemplateListColumns[0].defaultOrder,
        pageIndex: 1,
        pageSize: 200
      },
      isActive: this.active || false,
      finishFetchingTemplates: false,
      selectedBodyId: null,
      selectedTemplate: null,
      templates: null
    }
  },
  computed: {
    ...mapGetters('vehicleOptions', {
      storeBodyShapes: 'bodyShapes'
    }),
    bodyShapes() {
      return this.storeBodyShapes
    },
    bodyShape() {
      if (!this.vehicleBodyId) return null
      const [shape] = this.bodyShapes.filter(shape => shape.id == this.vehicleBodyId)
      return shape.name
    },
    isTemplateListNonEmpty() {
      if (this.templates) {
        return this.templates.length > 0
      }
      return false
    },
    isTemplateSelected() {
      return this.selectedTemplate !== null
    },
    label() {
      if (this.quoteTimesType == 'LTAR' || this.quoteTimesType == 'NTAR') {
        return `Please select a ${this.quoteTimesType} template`
      }
      return 'Please select a template (LTAR/NTAR options are excluded)'
    }
  },
  watch: {
    active(value) {
      this.isActive = value
    }
  },
  async created() {
    this.$showSpinner()
    this.finishFetchingTemplates = false
    await this.getQuoteTemplates()
    this.finishFetchingTemplates = true
    this.$hideSpinner()
  },
  methods: {
    add() {
      this.$emit('add', this.selectedTemplate.templateId)
      this.close()
    },
    close() {
      this.isActive = false
      this.$emit('update:active', false)
    },
    async getQuoteTemplates() {
      const quoteTemplates = await QuoteTemplateService.getQuoteTemplates(this.filter)
      const quoteRepairerTemplates = quoteTemplates.filter(template =>
        template.quoteType == 'Q' && template.bodyId == this.vehicleBodyId)

      if (this.quoteTimesType == 'LTAR') {
        this.templates = quoteRepairerTemplates.filter(template => template.timesType == 'LTAR')
      }

      if (this.quoteTimesType == 'NTAR') {
        this.templates = quoteRepairerTemplates.filter(template => template.timesType == 'NTAR')
      }

      if (this.quoteTimesType !== 'LTAR' && this.quoteTimesType !== 'NTAR') {
        this.templates = quoteRepairerTemplates.filter(template =>
          template.timesType && template.timesType !== 'NTAR' && template.timesType !== 'LTAR')
      }
    }
  }
}
</script>